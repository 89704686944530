
import { mapGetters, mapActions } from 'vuex';
import LOCALE_DESTINATION from '~/assets/constants/LOCALE_DESTINATION_COUNTRY';
import LOCALE_ORIGIN from '~/assets/constants/LOCALE_ORIGIN_COUNTRY';
import StelaInputText from '~/components/common/StelaInputText';
import StelaInputSelect from '~/components/common/StelaInputSelect';
import CustomQuotePanel from '~/components/panels/CustomQuotePanel';
import { SHIPPING_METHODS_ARRAY } from '~/assets/constants/SHIPPING_METHODS.js';

export default {
  name: 'StelaPriceCalculatorPanel',
  components: {
    CustomQuotePanel,
    StelaInputText,
    StelaInputSelect,
  },
  props: {
    address: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      plans: [],
      calculateEstimateNotFound: true,
      allShippingMethods: SHIPPING_METHODS_ARRAY,
      loading: false,
      destinationCountry: null,
      originCountry: null,
      weight: null,
      selectedPlan: null,
      kg: null,
      height: 1,
      width: 1,
      length: 1,
      preferredCurrency: 'USD',
      recipientState: '',
      deliveryChoice: null,
      selectedFreightOption: null,
      showExtraLargeModal: false,
      deliveryFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      shippingFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      totalFee: {
        charge: 0,
        currency: 'USD',
        display: '0',
        mainUnit: 0,
        subUnit: 0
      },
      showExtraLargeShipmentModal: false,
    };
  },
  computed: {
    ...mapGetters({
      supportedDestinationCountriesData: 'addressbook/supportedDestinationCountriesData',
      supportedOriginCountriesData: 'addressbook/supportedOriginCountriesData',
      statesCitiesDataIsLoading: 'addressbook/statesCitiesDataIsLoading',
      statesData: 'addressbook/statesData',
      allStatesData: 'addressbook/allStatesData',
      addresses: 'addressbook/addresses',
      plansDataIsLoading: 'plan/plansDataIsLoading',
    }),
    recipientStatesData () {
      return (this.destinationCountry?.value && this.allStatesData[this.destinationCountry?.value]) || this.statesData;
    },
    showCustomQuote () {
      if (this.selectedFreightOption?.value === 'AIR_FREIGHT' && this.weight >= 100) {
        return true;
      } else if (this.selectedFreightOption?.value === 'OCEAN_FREIGHT' && this.weight >= 250) {
        return true;
      }
      return false;
    },
    recipientStates () {
      return this.recipientStatesData.map(this.mapSelectOption);
    },
    deliveryChoicePickup () {
      return this.selectedDeliveryType === this.DELIVERY_CHOICE_PICKUP;
    },
    freightShippingGroupOptions () {
      return this.allShippingMethods.map(freight => ({
        label: freight.key.replace('_', ' '),
        value: freight.key
      }));
    },
    weightLimitConverter () {
      return {
        kg: {
          min: '1',
          max: '999999999999999',
        },
      };
    },
    selectedDeliveryType () {
      return this.deliveryChoice?.value;
    },
    getPlan () {
      return this.plans.find(plan => plan.name === 'PREMIUM');
    },
    defaultPlan () {
      return this.getPlan?.name;
    },
    formData () {
      return {
        weight: this.weight,
        length: this.length,
        width: this.width,
        height: this.height,
        originCountry: this.originCountry,
        destinationCountry: this.destinationCountry,
        freight_mode: this.selectedFreightOption?.value,
        plan_code: this.selectedPlan,
        recipientState: this.recipientState?.value,
        deliveryChoice: this.deliveryChoice,
      };
    },
    supportedDestinationCountryOptions () {
      return Object.values(this.excludeFlaggedCountries(LOCALE_DESTINATION.COUNTRIES, this.featureFlaggedDestinationCountries)).map(({ alpha2, emoji, name }) => ({
        label: `${emoji} ${name}`,
        value: alpha2,
      }));
    },
    supportedOriginCountryOptions () {
      return Object.values(this.excludeFlaggedCountries(LOCALE_ORIGIN.COUNTRIES, this.featureFlaggedOriginCountries)).map(({ alpha2, emoji, name }) => ({
        label: `${emoji} ${name}`,
        value: alpha2,
      }));
    },
    deliveryChoices () {
      if (this.destinationCountry && this.destinationCountry.value === LOCALE_DESTINATION.COUNTRY.GH.alpha2) {
        return [
          {
            label: 'Ghana Pickup',
            value: this.DELIVERY_CHOICE_PICKUP,
          },
          {
            label: 'Home Delivery',
            value: this.DELIVERY_CHOICE_HOME,
          },
        ];
      }

      return [
        {
          label: 'Home delivery',
          value: this.DELIVERY_CHOICE_HOME,
        },
        {
          label: 'Pickup',
          value: this.DELIVERY_CHOICE_PICKUP,
        },
      ];
    },
    getPreferredCurrencySymbol () {
      if (this.preferredCurrency === 'NGN') {
        return '₦';
      }
      if (this.preferredCurrency === 'USD') {
        return '$';
      }
      return '';
    },
    formattedTotalAmount () {
      return new Intl.NumberFormat('en-US').format(this.totalFee.mainUnit);
    },
  },
  watch: {
    destinationCountry () {
      this.deliveryChoice = this.deliveryChoices[0];
    },
    deliveryChoice (val) {
      if (val.value === this.DELIVERY_CHOICE_PICKUP) {
        this.recipientState = this.recipientStates.find(({ value }) => {
          return value === 'Lagos';
        });
      }
    },
  },
  mounted () {
    this.destinationCountry = this.supportedDestinationCountryOptions.find(({ value }) => {
      return value === LOCALE_DESTINATION.COUNTRY.NG.alpha2;
    });

    this.originCountry = this.supportedOriginCountryOptions.find(({ value }) => {
      return value === LOCALE_ORIGIN.COUNTRY.US.alpha2;
    });
    this.fetchPlans();
  },
  created () {
    this.unwatch = this.$watch(
      'formData',

      this.debounce(() => {
        if (this.weight && this.deliveryChoice.label && this.deliveryChoice.value && this.selectedPlan) {
          this.estimatePrice();
        }
      }, 1000
      )
    );
    if (this.address) {
      this.setFormFields();
    }
    if (this.address && this.address.country) {
      this.fetchStatesCities({ country: this.address.country });
      return;
    }
    this.fetchStatesCities({ country: 'NG' });
  },
  beforeDestroy () {
    this.unwatch();
  },
  methods: {
    ...mapActions({
      fetchSubscriptions: 'subscription/fetchSubscriptions',
      fetchStatesCities: 'addressbook/fetchStatesCities',
    }),
    async estimatePrice () {
      this.loading = true;
      this.calculateEstimateNotFound = false;
      try {
        const { deliveryChoice } = this.interpretDeliveryType(this.deliveryChoice?.value);
        const requestData = {
          pickup_country: this.destinationCountry?.value,
          origin_country: this.originCountry?.value,
          weight: Number(Number(this.weight).toFixed(2)),
          width: Number(Number(this.width).toFixed(2)),
          height: Number(Number(this.height).toFixed(2)),
          freight_mode: this.selectedFreightOption?.value,
          length: Number(Number(this.length).toFixed(2)),
          plan_code: this.selectedPlan,
          delivery_choice: deliveryChoice,
          origin_warehouse: 'US-TX-HOU',
          delivery_address: {
            country: this.destinationCountry?.value,
            state: this.recipientState?.value,
            name: 'UNKNOWN',
            phone: 'UNKNOWN',
            street: 'UNKNOWN',
            city: 'UNKNOWN'
          },
        };
        const { data } = await this.$api.shipping.orders.estimate({
          data: requestData,
        });
        if (data.payload) {
          this.deliveryFee = data.payload.estimate.deliveryFee;
          this.shippingFee = data.payload.estimate.shippingFee;
          this.totalFee = data.payload.estimate.total;
        }
      } catch (error) {
        this.calculateEstimateNotFound = true;
      } finally {
        this.loading = false;
      }
    },
    async fetchPlans () {
      this.fetchingPlans = true;
      try {
        const { data } = await this.$api.subscription.plans.list({});
        this.plans = data?.payload?.plans || [];
        this.selectedPlan = this.findPlan(this.plans, 'PREMIUM')?.code;
      } catch (error) {
        // this.handleServerError(error);
      } finally {
        this.fetchingPlans = false;
      }
    },
    findPlan (plans, planName) {
      return plans?.find(plan => plan.name === planName);
    },
    interpretDeliveryType (deliveryType) {
      const deliveryChoiceMap = {
        [this.DELIVERY_CHOICE_HOME]: this.DELIVERY_CHOICE_HOME,
        [this.DELIVERY_CHOICE_PICKUP]: this.DELIVERY_CHOICE_PICKUP,
      };

      const deliveryChoice = deliveryChoiceMap[deliveryType];
      const addressIsWithinLagos = deliveryType === this.DELIVERY_CHOICE_HOME_LAGOS;
      return { deliveryChoice, addressIsWithinLagos };
    },
  },
};
