const SHIPPING_METHODS = {
  AIR_FREIGHT: {
    key: 'AIR_FREIGHT',
    title: 'Air Shipping ($8/lbs)',
    price: '8',
    description: '',
  },
  OCEAN_FREIGHT: {
    key: 'OCEAN_FREIGHT',
    title: 'Ocean Shipping ($2/lbs)',
    price: '2',
    description: 'Consider Ocean Shipping for non-urgent shipments, as it not only offers incredible savings at $2 per pound but also ensures your goods reach their destination securely and affordably.',
  },
};
export const SHIPPING_METHODS_ARRAY = Object.values(SHIPPING_METHODS);
export default SHIPPING_METHODS;
