const LOCALE_ORIGIN_COUNTRY = {
  US: {
    alpha2: 'US',
    code: 'USA',
    name: 'United States',
    emoji: '🇺🇸',
    defaultWarehouseCode: 'US-TX-HOU',
    flagIconUrl: '/svg/flags/icon-flag-us.svg',
  },
  GB: {
    locale: 'en-GB',
    alpha2: 'GB',
    code: 'GBR',
    name: 'United Kingdom',
    emoji: '🇬🇧',
    defaultWarehouseCode: 'EU-UK-MAN',
    flagIconUrl: '/svg/flags/icon-flag-us.svg',
  },
  CN: {
    locale: 'zh-CN',
    alpha2: 'CN',
    code: 'CHN',
    name: 'China',
    emoji: '🇨🇳',
    defaultWarehouseCode: 'CN-CAN-GUA',
    flagIconUrl: '/svg/flags/icon-flag-cn.svg',
  },
};

export const LOCALE_ORIGIN_COUNTRIES = Object.values(LOCALE_ORIGIN_COUNTRY);

const LOCALE_ORIGIN = {
  COUNTRY: LOCALE_ORIGIN_COUNTRY,
  COUNTRIES: LOCALE_ORIGIN_COUNTRIES,
};

export const HOME_COUNTRY = LOCALE_ORIGIN_COUNTRY.US;

export default LOCALE_ORIGIN;
