

export default {
  name: 'CustomCalculatorQuote',
  components: {
  },
  props: {
  },
  data () {
    return {
      customshow: true,
    };
  },
  methods: {
  },
};
