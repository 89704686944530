
export default {
  props: {
    vModel: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    idName: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    selectOnTab: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    setInputValue (value) {
      this.$emit('input', value);
    }
  }
};
