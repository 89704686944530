
export default {
  name: 'InputText',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    idName: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    inputmode: {
      type: String,
      default: null,
    },
    step: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    appendText: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      showPassword: false,
      icons: {
        showPassword: '/svg/setting/icon-show-password.svg',
        hidePassword: '/svg/setting/icon-hide-password.svg',
      },
    };
  },
  computed: {
    computedType () {
      return this.type === 'password' && this.showPassword ? 'text' : this.type;
    },
    iconPassword () {
      return this.showPassword ? this.icons.showPassword : this.icons.hidePassword;
    },
  },
  methods: {
    onInput (event) {
      this.$emit('input', event.target.value);
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword;
    },
  },
};
